import {HYDRATE} from 'next-redux-wrapper';
import BaseQuery from '../baseQuery';
import {createApi} from '@reduxjs/toolkit/query/react';
import appConfig from '@/config';

export const emailService = createApi({
  reducerPath: 'emailService',
  baseQuery: BaseQuery(appConfig('local')),
  extractRehydrationInfo(action, {reducerPath}) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath];
    }
  },
  tagTypes: ['Email'],
  endpoints: builder => ({
    sendEmail: builder.mutation({
      query: ({data}) => ({
        url: 'api/send-email',
        body: data,
        method: 'POST',
      }),
    }),
  }),
});

export const {useSendEmailMutation} = emailService;
export const {sendEmail} = emailService.endpoints;
