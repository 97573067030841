import {URLS} from './constants';

function appConfig(base = 'aggregator', mode = null) {
  mode = mode ?? process?.env?.NEXT_PUBLIC_APP_MODE ?? 'development';
  const url = {
    aggregatorUrl: URLS.aggregator[mode.toLowerCase()] ?? URLS.aggregator['development'],
    localUrl: URLS.local[mode.toLowerCase()] ?? URLS.bunsar['development'],
    apiUrl: URLS.api[mode.toLowerCase()] ?? URLS.api['development'],
    emailUrl: URLS.email[mode.toLowerCase()] ?? URLS.email['development'],
  };

  return {
    baseUrl: url[base + 'Url'],
  };
}

export default appConfig;
