import Image from 'next/image';
import classNames from 'classnames';
import {useState} from 'react';
import {useGetFooterQuery} from '@/store/services';
import Links from '@/components/links';
import {useLanguage} from '@/hooks/LanguageContext';

const Footer = () => {
  const {selectedLanguage} = useLanguage();
  const {data} = useGetFooterQuery(selectedLanguage);

  const {LeftItems, HorizontalItems, VerticalItems} = data?.find(v => v.WidgetType === 43) || {};

  return (
    data && (
      <>
        <div className="w-full h-[506px] items-center content-between flex bg-primary max-lg:h-auto">
          <div className="mt-[118px] ml-[145px] mr-[147px] mb-[97px] flex max-lg:flex-col max-lg:m-0 max-lg:px-[24px] max-lg:pt-[64px]">
            <div className="flex flex-col w-[337px] lg:mr-[150px] max-lg:w-full">
              <div className="relative w-[220px] h-[30px] max-lg:w-[166px]">
                {LeftItems?.Logo?.Value && LeftItems?.Logo?.Url && (
                  <Links href={LeftItems.Logo.Url} passHref>
                    <Image src={LeftItems.Logo.Value} objectFit="contain" layout="fill" alt="Boyner Kurumsal" />
                  </Links>
                )}
              </div>
              <p className="text-white mt-[43px] max-lg:mt-[32] max-lg:text-[14px]">{LeftItems?.Text}</p>
              <Links className="mt-3" target="_blank" href="http://etbis.eticaret.gov.tr/sitedogrulama/c9bab77ed30c4e8dbab25e1f61e4dbe2">
                <Image
                  alt="etbis"
                  loading="lazy"
                  width="100"
                  height="117"
                  decoding="async"
                  data-nimg="1"
                  style={{color: 'transparent'}}
                  src="https://boyner-stook-frontend.mncdn.com/public/footer/benefit/pngs/etbis.png"
                />
              </Links>
            </div>
            <div className="grid grid-cols-4 gap-[24px] max-lg:hidden">
              {VerticalItems?.map((item, index) => (
                <MenuItem {...item} key={index} />
              ))}
            </div>
            <div className="flex flex-col mt-[44px] lg:hidden">
              {VerticalItems?.map((item, index) => (
                <MobileMenuItem {...item} key={index} />
              ))}
            </div>
          </div>
        </div>
        <div className="bg-[#3f3f4b] h-[132px] flex items-center text-white max-lg:flex-col max-lg:h-auto max-lg:p-[24px] justify-center">
          <div className="flex items-center">
            <p className="uppercase font-[540px] ml-[144px] text-[16px] max-lg:hidden">{HorizontalItems?.Title?.Value}</p>
            <div className="ml-[68px] text-[32px] max-lg:ml-0 flex">
              {HorizontalItems?.Items?.map(({Value, Url}, i) => (
                <Links key={i} href={Url ? Url : '/'} className="mr-[12px]">
                  {<Image src={Value} width={32} height={32} alt="Social" />}
                </Links>
              ))}
            </div>
          </div>
          <p className="ml-auto mr-[110px] text-[16px] max-lg:!mx-0 max-lg:mt-[35px]">{HorizontalItems?.CommercialTitle?.Value}</p>
        </div>
      </>
    )
  );
};

const MenuItem = ({Items}) => (
  <div>
    <ul className="flex flex-col gap-[10px] text-[16px] leading-[22px] text-white whitespace-nowrap">
      {Items.map(({Value, Url, Type}, index) => (
        <li className={classNames({'uppercase font-boldie mb-[19px]': Type === '1'})} key={index}>
          <Links href={Url ? Url : '/'}>{Value}</Links>
        </li>
      ))}
    </ul>
  </div>
);

const MobileMenuItem = ({Items}) => {
  const [isOpen, setIsOpen] = useState(false);
  const {Value} = Items.find(({Type}) => Type === '1');
  const children = Items.filter(({Type}) => Type !== '1');
  return (
    <div className="mb-[32px]">
      <div className="flex justify-between text-[#FFFFFF]" onClick={() => setIsOpen(!isOpen)}>
        <h4>{Value}</h4>
        <i className="icon chevron-down" />
      </div>
      <div
        className={classNames('pl-[10px] h-0 opacity-0 transition-[opacity] overflow-hidden', {
          'h-auto opacity-100 pt-[20px]': isOpen,
        })}>
        <ul>
          {children.map(({Value, Url}, index) => (
            <li className="mb-[12px] text-[#FFFFFF]" key={index}>
              <Links href={Url ? Url : '/'}>{Value}</Links>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Footer;
