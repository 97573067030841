const translations = {
  CorporateSalesContactForm: 'Corporate Sales Contact Form',
  WholesaleContactForm: 'Wholesale Contact Form',
  BecomeABusinessPartnerContactForm: 'Become a Business Partner Contact Form',
  ContactInformation: 'Contact information',
  YourName: 'Your Name',
  YourSurname: 'Your Surname',
  YourIDNumber: 'Your ID Number',
  YourFaxNumber: 'Your Fax Number',
  CompanyName: 'Company Name',
  CompanyRegistrationNumber: 'Company Registration Number (Optional)',
  CompanyFieldfActivity: 'Company Field of Activity',
  BankWhereTheCompanyWorks: 'Bank Where The Company Works',
  Department: 'Department',
  M2: 'Square Meter (m2)',
  NumberOfFloors: 'Number of Floors',
  FloorHeight: 'Floor Height (m)',
  Avm: 'Shopping Mall Where the Store Will Be Located',
  City: 'City',
  District: 'District',
  Town: 'Town',
  AddressDetail: 'Address Detail',
  YourPhone: 'Your Phone',
  Email: 'Email',
  YourMessage: 'Your Message',
  ConfirmEn: 'I have read the ',
  Confirm: ' and I accept it.',
  ConfirmationForm: 'Confirmation Form',
  Send: 'Send',
  ForAlYourQuestionsAndRequests: 'For all your questions and requests:',
  ThankYouWeHaveReceivedYourRequest: 'Thank you, we have received your request!',
  ModalContent: 'We will contact you as soon as possible regarding the issue via the contact information you shared with us.',
  Okay: 'Okay',
  GoToHomepage: 'Go to Homepage',
  SeeAll: 'See All',
  NotFoundContent: `We can't find the page you are looking for. You can return to the home page or access other information by exploring other options from the menu.`,
  Directions: 'Directions',
  'Near Me': 'Near Me',
  'Work Hours': 'Work Hours',
  Cities: 'Cities',
  'Show on Map': 'Show on Map',
  Stores: 'Stores',
  'We do not yet have a store in the location you are looking for.': 'We do not yet have a store in the location you are looking for.',
  'Type province, district or store name': 'Type province, district or store name',
  Address: 'Address',
  'Tax Name': 'Tax Name',
  'Tax Number': 'Tax Number',
  'Registration Number': 'Registration Number',
  AllStores: 'All Stores',
  Homepage: 'Homepage',
};

export default translations;
