import {fetchBaseQuery, retry} from '@reduxjs/toolkit/query';
import appConfig from '../config';

const BaseQuery = (baseUrl = null, clearHeader = false) =>
  retry(
    async (args, api, extraOptions) => {
      const result = await fetchBaseQuery(baseUrl ?? appConfig())(
        {
          ...args,
          timeout: 60000,
          headers: !clearHeader
            ? {
                ...args.headers,
              }
            : {},
          body: args?.body ?? args?.data ?? undefined,
        },
        api,
        extraOptions,
      );

      if (result?.error?.status === 'TIMEOUT_ERROR') {
        return {error: {error: {status: 'TIMEOUT_ERROR', message: 'Timeout error'}}};
      }
      if (result.error?.status === 401) {
        retry.fail(result.error);
      }
      if (result?.data?.Success === false) {
        return {
          error: result.data?.Exception ? {...result.data?.Exception, Result: result?.data?.Result ?? undefined} : {Message: result.data?.Message},
        };
      }

      return result;
    },
    {
      maxRetries: 1,
    },
  );

export default BaseQuery;
