import React, {createContext, useContext, useState, useEffect} from 'react';
import Cookies from 'js-cookie';
import translationsTR from '../locales/tr';
import translationsEN from '../locales/en';
import {useRouter} from 'next/router';

const LanguageContext = createContext();

const DEFAULT_LANGUAGE = 'tr';

// Language Provider
export function LanguageProvider({children}) {
  const router = useRouter();
  const routerLocale = router.locale;
  const [selectedLanguage, setSelectedLanguage] = useState(routerLocale === 'en' ? routerLocale : DEFAULT_LANGUAGE); // Default or cookie lang

  useEffect(() => {
    if (routerLocale === 'en') {
      Cookies.set('selectedLanguage', 'en');
    } else {
      Cookies.set('selectedLanguage', 'tr');
    }
  }, [routerLocale]);

  const changeLanguage = async lang => {
    setSelectedLanguage(lang);
    // Router.push(`/${lang === 'tr' ? '' : lang}`, undefined, {shallow: false});
    router.push(router.asPath, undefined, {locale: lang});
  };

  const translations = {
    tr: translationsTR,
    en: translationsEN,
  };

  function translate(key) {
    return translations[selectedLanguage][key];
  }

  return <LanguageContext.Provider value={{selectedLanguage, changeLanguage, translate}}>{children}</LanguageContext.Provider>;
}

// Language Hook
export function useLanguage() {
  return useContext(LanguageContext);
}
