import {HYDRATE} from 'next-redux-wrapper';
import BaseQuery from '../baseQuery';
import {createApi} from '@reduxjs/toolkit/query/react';

export const cmsService = createApi({
  reducerPath: 'cmsService',
  baseQuery: BaseQuery(),
  extractRehydrationInfo(action, {reducerPath}) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath];
    }
  },
  tagTypes: ['Cms'],
  endpoints: builder => ({
    getWidgets: builder.query({
      query: data => ({url: 'corporate', method: 'POST', body: {...data}}),
      transformResponse: response => response.Result,
    }),
    getHeader: builder.query({
      query: language => ({url: 'corporate', method: 'POST', body: {type: 7, name: 'Header', platform: '1,2,3', lang: language}}),
      transformResponse: response => response.Result,
    }),
    getFooter: builder.query({
      query: language => ({url: 'corporate', method: 'POST', body: {type: 7, name: 'Footer', platform: '1,2,3', lang: language}}),
      transformResponse: response => response.Result,
    }),
  }),
});

export const {useGetWidgetsQuery, useLazyGetWidgetsQuery, useGetHeaderQuery, useGetFooterQuery} = cmsService;
export const {getWidgets, getFooter, getHeader} = cmsService.endpoints;
