import {HYDRATE} from 'next-redux-wrapper';
import BaseQuery from '../baseQuery';
import {createApi} from '@reduxjs/toolkit/query/react';
import appConfig from '@/config';

export const addressService = createApi({
  reducerPath: 'addressService',
  baseQuery: BaseQuery(appConfig('api')),
  extractRehydrationInfo(action, {reducerPath}) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath];
    }
  },
  tagTypes: ['Address'],
  endpoints: builder => ({
    getCityList: builder.query({
      query: () => ({url: 'mbUser/GetCityList', params: {countryId: 1}, method: 'get'}),
      transformResponse: response => response.Result,
    }),
    getDistrictList: builder.query({
      query: ({cityId}) => ({url: 'mbUser/GetDistrictList', params: {cityId}, method: 'get'}),
      transformResponse: response => response.Result,
    }),
    getTownList: builder.query({
      query: ({districtId}) => ({url: 'mbUser/GetTownList', params: {districtId}, method: 'get'}),
      transformResponse: response => response.Result,
    }),
  }),
});

export const {useGetCityListQuery, useGetDistrictListQuery, useGetTownListQuery} = addressService;
export const {getCityList, getDistrictList, getTownList} = addressService.endpoints;
