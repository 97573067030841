export const URLS = {
  aggregator: {
    development: 'https://mpecom-aggregator-preprod.boyner.com.tr/',
    production: 'https://mpecom-aggregator-prod.boyner.com.tr/',
  },
  local: {
    development: 'http://boyner-corporate.aks01.shared-ecom-common.dev.boynercloud.io/',
    production: 'https://kurumsal.boyner.com.tr/',
  },
  api: {
    development: 'https://apigw.marketplace-ecom.preprod.boynercloud.io/mobile2/',
    production: 'https://mpecom-apigw-prod.boyner.com.tr/mobile2/',
  },
  email: {
    development: 'http://generic-api.aks01.ecom.preprod.boynercloud.io/',
    production: 'http://generic-api.aks01.ecom.prod.boynercloud.io/',
  },
};

export const fileDomain = 'https://boyner-stook-frontend.mncdn.com';

export const Configs = {};
