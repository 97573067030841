import {configureStore} from '@reduxjs/toolkit';
import {wrapMakeStore} from 'next-redux-cookie-wrapper';
import {createWrapper} from 'next-redux-wrapper';
import {addressService, cmsService, emailService} from './services';
import withDefaultprops from './withDefaultProps';

export const store = wrapMakeStore(preloadedState => {
  return configureStore({
    reducer: {
      [cmsService.reducerPath]: cmsService.reducer,
      [addressService.reducerPath]: addressService.reducer,
      [emailService.reducerPath]: emailService.reducer,
    },
    preloadedState,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({serializableCheck: false, devTools: process.env.NODE_ENV !== 'production'}).concat([
        cmsService.middleware,
        addressService.middleware,
        emailService.middleware,
      ]),
  });
});

export const wrapper = createWrapper(store, {debug: false});

export {withDefaultprops};
