import Image from 'next/image';
import classNames from 'classnames';
import {useCallback, useMemo, useState} from 'react';
import MobileMenu from './mobileMenu';
import {useGetHeaderQuery} from '@/store/services';
import {debounce} from 'lodash';
import Links from '@/components/links';
import {useLanguage} from '@/hooks/LanguageContext';
import React, {useEffect} from 'react';
import {useRouter} from 'next/router';
import {fileDomain} from '@/config/constants';

const Header = () => {
  const router = useRouter();
  const {selectedLanguage, changeLanguage} = useLanguage();
  const {data} = useGetHeaderQuery(selectedLanguage);
  const roots = data?.map(item => item.Items).flat();
  const [activeMenu, setActiveMenu] = useState(null);
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  useEffect(() => {
    const handleRouteComplete = () => handleMouseLeave();
    router.events.on('routeChangeComplete', handleRouteComplete);
    return () => {
      router.events.off('routeChangeComplete', handleRouteComplete);
    };
  });

  const handleLanguageChange = e => {
    const newLanguage = e.target.value;
    changeLanguage(newLanguage);
  };

  const handleMouseOver = useCallback(
    active => {
      setActiveMenu(prev => ({active, prev, show: !!activeMenu?.active}));
      if (!activeMenu?.active) {
        setTimeout(() => {
          setActiveMenu(prev => ({...prev, show: true}));
        }, 150);
      }
    },
    [activeMenu],
  );

  const handleMouseLeave = useCallback(() => {
    debounceOver?.cancel();
    setActiveMenu(prev => ({...prev, show: false}));
    setTimeout(() => {
      setActiveMenu(prev => ({...prev, active: null}));
    }, 150);
  }, [activeMenu]);

  const debounceOver = useMemo(() => debounce(handleMouseOver, 200), [handleMouseOver]);

  return (
    <header className="w-full h-[88px] pl-[48px] pr-[44px] items-center content-between flex relative max-lg:!px-[16px] max-lg:h-[64px] max-lg:justify-between">
      <button className="text-[32px] text-primary mr-[16px] hidden max-lg:flex" onClick={() => setShowMobileMenu(true)}>
        <i className="icon menu-bar" />
      </button>
      <Links
        href="/"
        className="relative xl:max-w-[147px] lg:max-w-[120px] max-lg:flex-grow-0 w-[147px] h-[20px] max-lg:w-[116px] max-lg:h-[16px]"
        passHref>
        <Image src={`${fileDomain}/public/boyner-corporate/logo.png`} layout="fill" objectFit="contain" alt="Boyner Kurumsal" />
      </Links>
      <div className="xl:ml-[72px] lg:ml-[6px] max-lg:flex-1">
        <ul
          className="menuWrapper flex text-[16px] leading-[22px] text-primary whitespace-nowrap max-lg:justify-end"
          onMouseLeave={() => handleMouseLeave(null)}>
          {roots?.map(({Title, Url, Items = [], Image: menuImage}, index) => (
            <li
              key={index}
              className="menuItem h-[88px] flex items-center xl:px-[26px] lg:px-[12px] max-lg:hidden"
              onMouseEnter={() => debounceOver(Items.length > 0 ? index + 1 : null)}>
              <Links href={Url}>{Title}</Links>
              {Items.length > 0 && (
                <div
                  className={classNames('overflow-hidden hidden opacity-0 transition-opacity duration-200 absolute w-full left-0 top-[88px] z-50', {
                    '!flex': activeMenu?.active === index + 1,
                    'opacity-100': activeMenu?.active === index + 1 && activeMenu.show,
                  })}>
                  <div className="flex-1 bg-white pl-[144px] pt-[92px] min-h-[851px]">
                    <ul className="flex flex-col text-[24px] leading-[34px]">
                      {[{Text: Title, Url, main: true}, ...Items].map(({Text, Url, main}, index) => (
                        <li
                          key={index}
                          className={classNames({
                            'mt-[72px] first-of-type:mt-0': main,
                          })}>
                          <Links
                            href={Url}
                            className={classNames('text-primary mb-[32px] block', {
                              'text-[32px] leading-[46px] items-center flex mb-[48px]': main,
                            })}>
                            {Text}
                            {main && <i className="icon text-shape-right text-[12px] ml-[12px] text-active" />}
                          </Links>
                        </li>
                      ))}
                    </ul>
                  </div>
                  {menuImage && (
                    <div className="flex-1 bg-[#F8F6F7] min-h-[851px] flex items-center">
                      <div className="-ml-[132px]">
                        <Image src={menuImage} width={996} height={484} alt="header-image" />
                      </div>
                    </div>
                  )}
                </div>
              )}
            </li>
          ))}
          <li className="text-[14px] font-boldie flex items-center h-[88px] pl-[26px] max-lg:h-[100%]">
            <Links href="https://www.boyner.com.tr/" passHref={true} className="flex text-active max-xl:text-primary">
              boyner.com.tr <i className="icon text-shape-right text-[6px] mt-[4px] ml-[2px] max-lg:text-active"></i>
            </Links>
          </li>
        </ul>
      </div>
      <div className="flex flex-1 text-[14px] font-boldie items-center justify-end max-lg:hidden">
        <div className="relative after:content-['\e905'] after:font-iconmoon after:absolute after:pointer-events-none after:right-0">
          <select className="appearance-none pr-[18px]" value={selectedLanguage} onChange={handleLanguageChange}>
            <option value="tr">TR</option>
            <option value="en">EN</option>
          </select>
        </div>
      </div>
      <MobileMenu show={showMobileMenu} onHide={setShowMobileMenu} />
    </header>
  );
};

export default Header;
