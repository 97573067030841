const translations = {
  CorporateSalesContactForm: 'Kurumsal Satış İletişim Formu',
  WholesaleContactForm: 'Toptan Satış İletişim Formu',
  BecomeABusinessPartnerContactForm: 'İş Ortağı Ol İletişim Formu',
  ContactInformation: 'İletişim Bilgileri',
  YourName: 'Adınız',
  YourSurname: 'Soyadınız',
  YourIDNumber: 'T.C. Kimlik Numaranız',
  YourFaxNumber: 'Fax Numaranız',
  CompanyName: 'Firma Adı',
  CompanyRegistrationNumber: 'Firma Sicil Numarası (Opsiyonel)',
  CompanyFieldfActivity: 'Firma Faaliyet Alanı',
  BankWhereTheCompanyWorks: 'Firmanın Çalıştığı Banka',
  Department: 'Departman',
  M2: 'Metrekaresi (m2)',
  NumberOfFloors: 'Kat Sayısı',
  FloorHeight: 'Kat Yüksekliği (m)',
  Avm: 'Mağazanın Konumlanacağı AVM',
  City: 'Şehir',
  District: 'İlçe',
  Town: 'Mahalle',
  AddressDetail: 'Adres Detay',
  YourPhone: 'Telefon Numaranız',
  Email: 'Email',
  YourMessage: 'Mesajınız',
  Confirm: `'ni okudum, kabul ediyorum.`,
  ConfirmationForm: 'KVKK Aydınlatma Metni',
  Send: 'Gönder',
  ForAlYourQuestionsAndRequests: 'Tüm soru ve talepleriniz için:',
  ThankYouWeHaveReceivedYourRequest: 'Teşekkürler, talebinizi aldık!',
  ModalContent: 'Bizimle paylaştığınız iletişim bilgilerin üzerinden konu ile ilgili en kısa sürede sizinle iletişime geçeceğiz.',
  Okay: 'Tamam',
  GoToHomepage: 'Anasayfaya Git',
  SeeAll: 'Tümünü Gör',
  NotFoundContent: 'Aradığın sayfayı bulamıyoruz. Ana sayfaya dönebilir veya menüden diğer seçenekleri keşfederek diğer bilgilere ulaşabililirsin.',
  Directions: 'Yol Tarifi',
  'Near Me': 'Yakınımda',
  'Work Hours': 'Çalışma Saatleri',
  Cities: 'Şehirler',
  'Show on Map': 'Haritada Gör',
  Stores: 'Mağazalar',
  'We do not yet have a store in the location you are looking for.': 'Aradığın konumda henüz mağazamız bulunmamaktadır.',
  'Type province, district or store name': 'İl, ilçe veya mağaza adı yazın',
  Address: 'Adres',
  'Tax Name': 'Vergi Dairesi',
  'Tax Number': 'Vergi Numarası',
  'Registration Number': 'Sicil Numarası',
  'Registry Office': 'Ticaret Sicil Memurluğu',
  AllStores: 'Tüm Mağazalar',
  Homepage: 'Anasayfa',
};

export default translations;
